import { app } from '@/main.js'
// importing axios if logging causes an error
// we don't want it to go into a recursive loop
// of logging forever
// so by importing a new instance of axios it
// will not any error that happens when using it
import axios from 'axios'

export const logError = function (message) {
  axios.post(`/api/frontenderror/write`, { message })
}
export const logErrorSantander = function (input) {
  axios.post(`/api/frontenderror/santander`, input)
}

export const logErrorSamlino = function (input) {
  axios.post(`/api/frontenderror/samlino`, input)
}

export const setupErrorLogging = function () {
  window.onerror = function (msg, url, lineNo, columnNo, error) {
    const string = msg.toLowerCase()
    const substring = 'script error'
    if (string.indexOf(substring) > -1) {
      logError('External Script Error - ' + JSON.stringify(string))
    } else {
      const message = [
        'Message: ' + msg,
        'URL: ' + url,
        'Line: ' + lineNo,
        'Column: ' + columnNo,
        'Error object: ' + JSON.stringify(error),
      ].join(' - ')

      handleError()
      logError(message)
    }

    return false
  }
}

export const handleError = errorMessage => {
  app.mitt.emit('overlay-inclosable', app)
  app.$alert(
    errorMessage
      ? errorMessage
      : 'Der er desværre sket en fejl. Genindlæs vinduet og prøv igen. Hvis fejlen fortsætter er du velkommen til at kontakte vores support.',
    '',
    'error',
    {
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Luk',
      buttonsStyling: false,
      onClose: () => {
        app.mitt.emit('overlay-closable', app)
      },
    }
  )
}
