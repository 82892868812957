<template>
  <div class="login-page">
    <Login
      :inPage="true"
      :login-options="loginOptions">
    </Login>
  </div>
</template>

<script>
import { app } from '@/main'

import Login from './Login.vue'

export default {
  components: {
    Login,
  },
  props: {
    loginOptions: { default: null, type: Object},
  },
  created() {
    app.mitt.on('login-complete', this.formLogin)
  },
  methods: {
    formLogin() {
      let path = '/'
      const urlParameters = location.search
        .substring(1, location.search.length)
        .split('&')

      urlParameters.forEach(item => {
        if (item.split('=')[0] === 'redirectUrl') {
          path = decodeURIComponent(item.split('=')[1])

          // if // is included - it will redirect to another website
          // this is 100% a hacker attack so we will redirect to the frontpage
          if (path.includes('//')) {
            path = '/'
          }
        }
      })

      setTimeout(() => {
        location.href = path
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
@import 'LoginPage.scss';
</style>
