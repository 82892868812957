<template>
  <div>
    <div class="grid gap-6">
      <!-- Email input -->
      <div class="relative">
        <input
          title="E-mail"
          type="text"
          name="Email"
          id="userLoginEmail"
          placeholder="E-mail"
          autocomplete="email username"
          class="form-control"
          v-model="email"
          @keyup.enter="emailInputEnterTrigger()"
          ref="userLoginEmailInput"
        />
        <label class="user__input-label control-label" for="userLoginEmail"
          >E-mail</label
        >
        <p class="mt-2 -mb-2 flex items-center gap-2" v-if="errorMessageEmail">
          <span class="error__attention"></span>
          <span class="text-xxs text-red-500">
            {{ errorMessageEmail }}
          </span>
        </p>
      </div>
      <!-- Password input -->
      <div class="relative" v-if="!forgotPassword">
        <input
          title="Kodeord"
          type="password"
          name="Password"
          id="userLoginPassword"
          placeholder="Kodeord"
          autocomplete="current-password"
          class="form-control"
          v-model="password"
          @keyup.enter="login()"
        />
        <label class="user__input-label control-label" for="userLoginPassword"
          >Kodeord</label
        >
        <p
          class="mt-2 -mb-2 flex items-center gap-2"
          v-if="errorMessagePassword"
        >
          <span class="error__attention"></span>
          <span class="text-xxs text-red-500">
            {{ errorMessagePassword }}
          </span>
        </p>
      </div>
    </div>

    <div class="mt-6 grid gap-6">
      <!-- Forgot password -->
      <p class="text-gray-600" v-if="forgotPassword">
        Indtast din emailadresse, og vi sender dig et link til at nulstille din
        adgangskode.
      </p>
      <div
        class="w-full flex justify-end text-green-500 font-semibold transition-colors duration-500"
      >
        <a
          v-if="!forgotPassword"
          class="hover:text-green-700"
          href="#"
          @click.stop.prevent="toggleForgotPassword()"
        >
          Glemt kodeord?
        </a>
        <a
          v-else
          class="hover:text-green-700"
          href="#"
          @click.stop.prevent="toggleForgotPassword()"
        >
          Tilbage til Login
        </a>
      </div>
      <div class="w-full">
        <button
          v-if="!forgotPassword"
          class="w-full p-2 text-center font-semibold rounded-md bg-green-500 hover:bg-green-700 text-white transition-colors duration-500"
          @click="login()"
        >
          Log ind
        </button>
        <button
          v-if="forgotPassword"
          type="button"
          class="w-full p-2 text-center font-semibold rounded-md bg-green-500 hover:bg-green-700 text-white transition-colors duration-500"
          :disabled="inProgress"
          @click="resetPassword()"
        >
          Nulstil kodeord
          <ButtonSpinner v-if="inProgress" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { app } from '@/main'

import ButtonSpinner from '@components/Shared/ButtonSpinner/ButtonSpinner.vue'

export default {
  components: {
    ButtonSpinner,
  },
  props: {
    eventBus: { default: null, type: Object },
  },
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      forgotPassword: false,
      showResendVerificationEmailButton: false,

      errorMessageEmail: '',
      errorMessagePassword: '',
      errorMessageFacebook: '',

      inProgress: false,
      errors: [],
    }
  },
  methods: {
    isNullOrEmpty(str) {
      if (str === null) return true
      if (typeof str === 'undefined') return true
      if (str === '') return true
      if (str.trim() === '') return true
      return false
    },
    clearErrorMessages() {
      this.errorMessageEmail = ''
      this.errorMessagePassword = ''
      this.errorMessageFacebook = ''
    },
    formValidation() {
      this.clearErrorMessages()

      if (this.isNullOrEmpty(this.email)) {
        this.errorMessageEmail = 'E-mail adresse må ikke være tom'
      }

      if (!this.forgotPassword && this.isNullOrEmpty(this.password)) {
        this.errorMessagePassword = 'Kodeord må ikke være tomt'
      }
    },
    checkErrors(errorsArray) {
      errorsArray.forEach(item => {
        const errorItem = item.errorItem.toLowerCase()
        const errorMessage = item.errorMessage

        if (errorItem === 'email') {
          this.errorMessageEmail = errorMessage
        }
        if (errorItem === 'emailverified') {
          this.errorMessageEmail = errorMessage
          this.showResendVerificationEmailButton = true
        } else {
          this.showResendVerificationEmailButton = false
        }
        if (errorItem === 'password') {
          this.errorMessagePassword = errorMessage
        }
        if (errorItem === 'facebook') {
          this.errorMessageFacebook = errorMessage
        }
      })
    },
    emailInputEnterTrigger() {
      if (this.forgotPassword) {
        this.resetPassword()
      } else {
        this.login()
      }
    },
    login() {
      this.inProgress = true
      this.formValidation()

      if (this.errorMessageEmail !== '' || this.errorMessagePassword !== '') {
        this.inProgress = false
        return
      }

      const data = {
        email: this.email,
        password: this.password,
        remember: this.rememberMe,
      }

      const owner = this

      app.axios
        .post('/Api/User/Login', JSON.stringify(data))
        .then(resp => {
          owner.inProgress = false
          if (
            resp.data.errorCollection &&
            resp.data.errorCollection.length > 0
          ) {
            owner.errors = resp.data.errorCollection
            owner.checkErrors(owner.errors)
          } else {
            // LoggedInAsUser
            window.history.replaceState(null, '', null)

            app.toast.success('Du er nu logget ind')
            owner.showResendVerificationEmailButton = false
            let favoriteIdList = []
            if (
              resp.data &&
              resp.data.data &&
              resp.data.data.favoriteIdList !== undefined
            ) {
              favoriteIdList = resp.data.data.favoriteIdList
            }

            app.mitt.emit('login-complete', favoriteIdList)
          }
        })
        .catch(() => {
          owner.inProgress = false
        })
    },
    resetPassword() {
      this.inProgress = true
      this.formValidation()

      if (this.errorMessageEmail !== '' || this.errorMessagePassword !== '') {
        this.inProgress = false
        return
      }

      const data = {
        email: this.email,
      }

      const owner = this

      app.axios
        .post('/Api/User/ApplyForResetPassword', JSON.stringify(data))
        .then(resp => {
          if (
            resp.data.errorCollection &&
            resp.data.errorCollection.length == 0
          ) {
            // LoggedInAsUser
            app.toast.success(
              'Der er blevet sendt en e-mail til dig hvor du kan nulstille dit kodeord.',
              'Luk',
              true
            )
            owner.email = ''
            owner.inProgress = false
            owner.forgotPassword = false
            owner.history.pop()
          } else {
            if (
              resp.data.errorCollection &&
              resp.data.errorCollection.length > 0
            ) {
              owner.errors = resp.data.errorCollection
              owner.checkErrors(this.errors)
              owner.inProgress = false
            } else {
              owner.inProgress = false
            }
          }
        })
    },
    resendActivationEmail() {
      this.inProgress = true

      if (!this.showResendVerificationEmailButton) {
        this.inProgress = false
        return
      }

      const data = {
        email: this.email,
      }

      const owner = this

      app.axios
        .post('/Api/User/ResendActivationEmail', JSON.stringify(data))
        .then(resp => {
          if (
            resp.data.errorCollection &&
            resp.data.errorCollection.length == 0
          ) {
            // LoggedInAsUser
            app.toast.success(
              'Vi har nu sendt en nu aktiveringsmail. Der kan gå et par minutter før du modtager den. Husk at kigge i spam.',
              'Luk',
              true
            )
            owner.showResendVerificationEmailButton = false
            owner.errorMessageEmail =
              'Aktivér din konto ved at trykke på knappen i mailen'
            owner.inProgress = false
            return
          }

          owner.errors = resp.data.errorCollection
          owner.checkErrors(this.errors)
          owner.inProgress = false
        })
    },
    toggleForgotPassword() {
      this.clearErrorMessages()
      setTimeout(() => {
        // Denne timeout ligger er sat grundet race condition med handleDocumentClick i functions.js. Uden timeout, vil UI skifte først, hvorefter checket for overlay-master class vil fejle, da anchor tag'et fra eventet ikke længere findes i UI.
        if (this.forgotPassword) {
          this.forgotPassword = false
        } else {
          this.forgotPassword = true
          this.showResendVerificationEmailButton = false
        }
        this.password = ''
      }, 100)
    },
    overlayClosed() {
      this.clearErrorMessages()
      this.forgotPassword = false
      this.email = ''
      this.password = ''
      this.showResendVerificationEmailButton = false
    },
    setRemoteFocus() {
      setTimeout(() => {
        this.setFocus()
      }, 450)
    },
    setFocus() {
      setTimeout(() => {
        this.$refs.userLoginEmailInput.focus()
      }, 50)
    },
  },
  watch: {
    email() {
      this.errorMessageEmail = ''
      this.showResendVerificationEmailButton = false
    },
    password() {
      this.errorMessagePassword = ''
    },
  },
  created() {
    if (!this.eventBus) return

    this.eventBus.on('overlay-closing', this.overlayClosed)
    this.eventBus.on('overlay-open', this.setRemoteFocus)
  },
  beforeUnmount() {
    if (!this.eventBus) return

    this.eventBus.off('overlay-closing', this.overlayClosed)
    this.eventBus.off('overlay-open', this.setRemoteFocus)
  },
}
</script>

<style lang="scss">
@import '../User.scss';
</style>
