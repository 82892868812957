<template>
  <div class="reset-password-page">
    <h1>Ny kode</h1>
    <p v-html="restrictions"></p>
    <UserResetPassword :token="token" />
  </div>
</template>

<script>
import UserResetPassword from './UserResetPassword.vue'

export default {
  components: {
    UserResetPassword,
  },
  props: {
    token: { default: '', type: String },
    restrictions: { default: '', type: String },
  },
}
</script>

<style lang="scss">
@import 'ResetPasswordPage.scss';
</style>
