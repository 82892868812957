<template>
  <form method="post" action="create">
    <div class="grid gap-6">
      <!-- Email input -->
      <div class="relative">
        <input
          title="E-mail"
          type="text"
          name="Email"
          id="userCreateEmail"
          placeholder="E-mail"
          autocomplete="email username"
          class="user__input form-control"
          v-model="email"
          ref="userCreateEmailInput"
        />
        <label
          class="user__input-label control-label"
          for="userCreateEmailInput"
          >E-mail</label
        >
        <p class="mt-2 -mb-2 flex items-center gap-2" v-if="errorMessageEmail">
          <span class="error__attention"></span>
          <span class="text-xxs text-red-500">
            {{ errorMessageEmail }}
          </span>
        </p>
      </div>

      <!-- Password input -->
      <div class="relative">
        <PasswordStrength :password="password" :minLength="minLength" />
        <input
          title="Kodeord"
          type="password"
          name="Password"
          id="userCreatePassword"
          placeholder="Kodeord"
          autocomplete="new-password"
          class="user__input form-control"
          v-model="password"
        />
        <label class="user__input-label control-label" for="userLoginPassword"
          >Kodeord</label
        >
        <p
          class="mt-2 -mb-2 flex items-center gap-2"
          v-if="errorMessagePassword"
        >
          <span class="error__attention"></span>
          <span class="text-xxs text-red-500">
            {{ errorMessagePassword }}
          </span>
        </p>
      </div>

      <!-- Repeat Password input -->
      <div class="relative">
        <input
          title="Gentag kodeord"
          type="password"
          name="PasswordRepeat"
          id="userCreatePasswordRepeat"
          placeholder="Gentag kodeord"
          autocomplete="new-password"
          class="user__input form-control"
          v-model="passwordRepeat"
        />
        <label class="user__input-label control-label" for="userLoginPassword"
          >Gentag kodeord</label
        >
        <p
          class="mt-2 -mb-2 flex items-center gap-2"
          v-if="errorMessagePasswordRepeat"
        >
          <span class="error__attention"></span>
          <span class="text-xxs text-red-500">
            {{ errorMessagePasswordRepeat }}
          </span>
        </p>
      </div>
    </div>

    <div class="mt-6 grid gap-6">
      <div class="user__checkbox-container checkbox checkbox--highlight">
        <input
          class="user__checkbox"
          aria-hidden="true"
          type="checkbox"
          id="newsletterAccept"
          v-model="newsletterAccept"
        />
        <label for="newsletterAccept" class="user__checkbox-label">
          <span class="user__checkbox-icon input-visual"></span>
          <span class="user__checkbox-text"
            >Ja tak, jeg vil gerne modtage Biltorvets nyhedsbrev.
          </span>
        </label>
      </div>
      <button
        class="w-full p-2 text-center font-semibold rounded-md bg-green-500 hover:bg-green-700 text-white transition-colors duration-500"
        :disabled="inProgress"
        @click.prevent.stop="createUser()"
      >
        Opret bruger
        <ButtonSpinner v-if="inProgress" />
      </button>
    </div>
  </form>
</template>

<script>
import { app } from '@/main'

import { LogUserCreatedByWebsite } from '@/events'
import ButtonSpinner from '@components/Shared/ButtonSpinner/ButtonSpinner.vue'
import PasswordStrength from '@components/Shared/PasswordStrength/PasswordStrength.vue'

export default {
  components: {
    ButtonSpinner,
    PasswordStrength,
  },
  props: {
    eventBus: { default: null, type: Object },
    minLength: { default: 4, type: Number },
  },
  data() {
    return {
      email: '',
      password: '',
      passwordRepeat: '',
      newsletterAccept: false,

      errorHappened: false,

      errorMessageEmail: '',
      errorMessagePassword: '',
      errorMessagePasswordRepeat: '',
      errorMessageFacebook: '',

      inProgress: false,
      errors: [],
    }
  },
  methods: {
    isNullOrEmpty(str) {
      if (str === null) return true
      if (typeof str === 'undefined') return true
      if (str === '') return true
      if (str.trim() === '') return true
      return false
    },
    clearErrorMessages() {
      this.errorHappened = false
      this.errorMessageEmail = ''
      this.errorMessagePassword = ''
      this.errorMessagePasswordRepeat = ''
      this.errorMessageFacebook = ''
    },
    clearAll() {
      this.clearErrorMessages()
      this.email = ''
      this.password = ''
      this.passwordRepeat = ''
      this.newsletterAccept = false
    },
    formValidation() {
      this.clearErrorMessages()

      if (this.isNullOrEmpty(this.email)) {
        this.errorMessageEmail = 'E-mail adresse må ikke være tom'
        this.errorHappened = true
      }

      if (this.isNullOrEmpty(this.password)) {
        this.errorMessagePassword = 'Kodeord må ikke være tomt'
        this.errorHappened = true
      }

      if (this.password !== this.passwordRepeat) {
        this.errorMessagePasswordRepeat = 'Kodeord matcher ikke hinanden'
        this.errorHappened = true
      }

      if (this.isNullOrEmpty(this.passwordRepeat)) {
        this.errorMessagePasswordRepeat = 'Gentag venligst kodeord'
        this.errorHappened = true
      }
    },
    checkErrors(errorsArray) {
      errorsArray.forEach(item => {
        const errorItem = item.errorItem.toLowerCase()
        const errorMessage = item.errorMessage

        if (errorItem === 'email') {
          this.errorMessageEmail = errorMessage
        }
        if (errorItem === 'password') {
          this.errorMessagePassword = errorMessage
        }
        if (errorItem === 'facebook') {
          this.errorMessageFacebook = errorMessage
        }
        if (errorItem === 'repeat') {
          this.errorMessagePassword = errorMessage
        }
      })
    },
    createUser() {
      this.inProgress = true
      this.formValidation()

      if (this.errorHappened) {
        this.inProgress = false
        return
      }

      const data = {
        email: this.email,
        password: this.password,
        newsletterAccept: this.newsletterAccept,
        termsAcceptance: this.termsAcceptance,
      }

      const owner = this
      app.axios.post('/Api/User/Create', JSON.stringify(data)).then(resp => {
        owner.inProgress = false

        if (resp.data.errorCollection && resp.data.errorCollection.length > 0) {
          owner.errors = resp.data.errorCollection
          owner.checkErrors(this.errors)
        } else {
          app.mitt.emit('user-creation-complete')

          owner.clearAll()

          history.replaceState(null, '', null)

          app.toast.success(
            'Din bruger er nu oprettet. For at kunne logge ind, skal du aktivere brugeren. Vi har sendt dig en mail hvorfra du kan gøre dette. Husk evt. at kigge i din spam folder.',
            'Fortsæt',
            true
          )

          LogUserCreatedByWebsite()
        }
      })
    },
    setFocus() {
      setTimeout(() => {
        this.$refs.userCreateEmailInput.focus()
      }, 50)
    },
  },
  watch: {
    email() {
      this.errorMessageEmail = ''
    },
    password() {
      this.errorMessagePassword = ''
    },
    passwordRepeat() {
      this.errorMessagePassword = ''
    },
  },
}
</script>

<style lang="scss">
@import '../User.scss';
</style>
