<template>
  <div class="mx-auto">
    <div class="hidden md:flex justify-center mb-6">
      <img
        class="w-12 h-12"
        src="/static/img/vector/logo-green.svg"
        alt="Biltorvet logo"
      />
    </div>
    <h2 v-if="createUserActive" class="text-4xl font-bold text-center">
      Opret en konto hos Biltorvet
    </h2>
    <h2 v-if="!createUserActive" class="text-4xl font-bold text-center">
      Log ind på din konto
    </h2>
    <div
      class="mt-4 md:mt-10 mx-auto w-full max-w-lg"
      :class="{
        'bg-white py-8 px-6 md:p-12 rounded-lg shadow':
          loginDesign === 'regular',
      }"
    >
      <UserLogin
        v-if="!createUserActive"
        :event-bus="eventBus"
        ref="login"
      ></UserLogin>
      <UserCreate
        v-if="createUserActive"
        :event-bus="eventBus"
        ref="create"
      ></UserCreate>

      <div v-if="loginOptions">
        <div class="mt-10 flex items-center justify-between gap-x-6">
          <div class="w-full h-px bg-gray-200"></div>
          <p class="w-full whitespace-nowrap">Eller fortsæt med</p>
          <div class="w-full h-px bg-gray-200"></div>
        </div>

        <div class="mt-6 grid gap-6 mx-auto" style="max-width: 295px">
          <p v-if="!cookieConsent" class="text-red-500 text-xss text-center">
            For at logge ind med følgende, skal du godkende cookies
          </p>

          <div v-if="loginOptions?.googleEnabled" class="h-10 w-full">
            <button
              v-if="!cookieConsent"
              disabled
              style="color: #4285f4; border-color: #4285f4"
              class="h-10 w-full p-2 text-center bg-white border border-solid opacity-20 pointer-events-none cursor-not-allowed flex items-center justify-center gap-2 font-semibold rounded-md text-white focus:opacity-80 hover:opacity-80 transition-colors duration-500"
            >
              <img alt="Google" src="/static/icons/google_logo.svg" />
              <span>Fortsæt med Google</span>
            </button>
            <div v-if="cookieConsent" class="h-10 w-full flex justify-center">
              <div class="w-full" ref="googleSignin"></div>
            </div>
          </div>

          <div v-if="loginOptions?.facebookEnabled" class="h-10">
            <button
              :disabled="!cookieConsent"
              id="facebook-login"
              @click="onFacebookLogin"
              style="background-color: #0866ff"
              class="h-10 w-full p-2 text-center flex items-center justify-center gap-2 font-semibold rounded-md text-white focus:opacity-80 hover:opacity-80 transition-colors duration-500"
              :class="{
                'opacity-20 pointer-events-none cursor-not-allowed ': !cookieConsent,
              }"
            >
              <img alt="Facebook" src="/static/icons/facebook_logo_white.svg" />
              <span class="mt-0.5">Fortsæt med Facebook</span>
            </button>
          </div>

          <div v-if="loginOptions?.appleEnabled" class="h-10">
            <button
              :disabled="!cookieConsent"
              @click="onAppleLogin"
              style="background-color: #000000"
              class="h-10 w-full p-2 text-center flex items-center justify-center gap-2 font-semibold rounded-md text-white focus:opacity-80 hover:opacity-80 transition-colors duration-500"
              :class="{
                'opacity-20 pointer-events-none cursor-not-allowed ': !cookieConsent,
              }"
            >
              <img alt="Apple" src="/static/icons/apple_logo_white.svg" />
              <span>Fortsæt med Apple</span>
            </button>
          </div>
        </div>
      </div>
      <p
        class="text-center mt-6 font-ligth text-xss text-gray-600 mx-auto"
        style="max-width: 295px"
      >
        Ved at fortsætte accepterer du Biltorvets
        <a
          class="underline"
          href="/bruger/betingelser"
          target="_blank"
          rel="noopener"
          >brugsvilkår</a
        >.
      </p>
    </div>
    <div
      v-if="!createUserActive"
      class="mt-10 mx-auto w-full max-w-lg px-6 sm:px-12 text-center"
    >
      <p class="text-base text-gray-600 font-normal">
        Har du ikke en bruger?
        <button
          class="text-green-500 hover:text-green-700 font-semibold transition-colors duration-500"
          @click="toggleSwitcher(true)"
        >
          Opret en konto hos Biltorvet
        </button>
      </p>
      <p class="text-xss text-gray-600 mt-3">
        Når du opretter en profil, kan du gemme en annonce som favorit, oprette
        søgeagenter og sætte din egen bil til salg gratis.
      </p>
    </div>
    <div
      v-if="createUserActive"
      class="mt-10 mx-auto w-full max-w-lg px-6 sm:px-12 text-center"
    >
      <p class="text-base text-gray-600 font-normal">
        Har du allerede en bruger?
        <button
          class="text-green-500 hover:text-green-700 font-semibold transition-colors duration-500"
          @click="toggleSwitcher(false)"
        >
          Log ind på Biltorvet
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import { app } from '@/main'
import UserCreate from '../Create/UserCreate.vue'
import UserLogin from './UserLogin.vue'

/* global google */
/* global FB */
/* global AppleID */

export default {
  components: {
    UserCreate,
    UserLogin,
  },
  props: {
    eventBus: { default: null, type: Object },
    inPage: { default: false, type: Boolean },
    createUser: { default: false, type: Boolean },
    loginOptions: { default: null, type: Object },
    loginDesign: { default: 'regular', type: String },
  },
  data() {
    return {
      createUserActive: false,
      cookieConsent: false,
      error: '',
      loginLoaded: false,
    }
  },
  mounted() {
    window.addEventListener(
      'CookieInformationConsentGiven',
      this.cookieConsentGiven
    )

    if (this.cookieConsent) {
      this.loadLogins()
    }

    this.createUserActive = this.createUser

    if (!this.eventBus) return
    app.mitt.on('user-creation-complete', this.switchToLogin)
  },
  unmounted() {
    window.removeEventListener(
      'CookieInformationConsentGiven',
      this.cookieConsentGiven
    )
  },
  created() {
    this.cookieConsent = window.CookieInformation?.getConsentGivenFor(
      'cookie_cat_functional'
    )

    this.$watch(
      () =>
        window.CookieInformation?.getConsentGivenFor('cookie_cat_functional'),
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.cookieConsent = newValue
        }
      }
    )

    window.fbAsyncInit = () => {
      FB.init({
        appId: this.loginOptions?.facebookAppId,
        cookie: true,
        status: true,
        xfbml: true,
        version: 'v18.0',
      })
    }

    if (!this.eventBus) return

    this.eventbus.on('overlay-open', this.switchToLogin)
  },
  beforeUnmount() {
    if (!this.eventBus) return

    this.eventbus.off('overlay-open', this.switchToLogin)
  },
  methods: {
    cookieConsentGiven() {
      this.cookieConsent = window.CookieInformation?.getConsentGivenFor(
        'cookie_cat_functional'
      )
      if (this.cookieConsent) {
        this.loadLogins()
      }
    },
    loadLogins() {
      if (this.loginLoaded) {
        return
      }
      this.loginOptions?.googleEnabled &&
        this.loadJs(
          'gsi-client',
          'https://accounts.google.com/gsi/client',
          () => {
            google.accounts.id.initialize({
              client_id: this.loginOptions?.googleClientId,
              context: 'signin',
              ux_mode: 'popup',
              auto_select: false,
              itp_support: true,
              callback: this.onGoogleLogin,
            })
            google.accounts.id.renderButton(this.$refs.googleSignin, {
              type: 'standard',
              shape: 'rectangular',
              theme: 'filled_blue',
              text: 'continue_with',
              size: 'large',
              logo_alignment: 'left',
              width: 295,
            })
          }
        )
      this.loginOptions?.facebookEnabled &&
        this.loadJs('facebook-jssdk', '//connect.facebook.net/da_DK/sdk.js')
      this.loginOptions?.appleEnabled &&
        this.loadJs(
          'appleid-auth',
          'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/da_DK/appleid.auth.js',
          () => {
            AppleID.auth.init({
              clientId: this.loginOptions?.appleClientId,
              scope: 'email name',
              redirectURI: this.loginOptions?.appleRedirectUri,
              state: 'SignInUserAuthenticationRequest',
              usePopup: true,
            })
          }
        )
      this.loginLoaded = true
    },
    loadJs(id, src, onLoaded) {
      let firstScript = document.getElementsByTagName('script')[0]
      if (document.getElementById(id)) {
        if (onLoaded) {
          setTimeout(onLoaded)
        }
        return
      }
      let script = document.createElement('script')
      script.id = id
      script.src = src
      if (onLoaded) {
        script.addEventListener('load', () => setTimeout(onLoaded))
      }
      firstScript.parentNode.insertBefore(script, firstScript)
    },
    socialLogin(url, data, owner) {
      app.axios.post(url, JSON.stringify(data)).then(resp => {
        owner.inProgress = false
        if (resp.data.errorCollection && resp.data.errorCollection.length > 0) {
          owner.errors = resp.data.errorCollection
          owner.checkErrors(this.errors)
        } else {
          // LoggedInAsUser
          app.toast.success('Du er nu logget ind')

          let favorites = []
          if (
            resp.data &&
            resp.data.data &&
            resp.data.data.favoriteIdList !== undefined
          ) {
            favorites = resp.data.data.favoriteIdList
          }

          app.mitt.emit('login-complete', favorites)
        }
      })
    },
    switchToLogin() {
      this.toggleSwitcher(false)
    },
    toggleSwitcher(createUser) {
      if (createUser === this.createUserActive) return

      if (createUser) {
        this.createUserActive = true
        setTimeout(() => {
          this.$refs.create.setFocus()
        }, 200)
      } else {
        this.createUserActive = false
        setTimeout(() => {
          this.$refs.login.setFocus()
        }, 200)
      }
    },
    onGoogleLogin(response) {
      const owner = this

      if (response.credential) {
        const data = {
          googleToken: response.credential,
        }
        this.socialLogin('/Api/User/LoginWithGoogle', data, owner)
      }
    },
    onFacebookLogin() {
      const owner = this

      FB.login(
        fbResponse => {
          if (
            fbResponse.status === 'connected' &&
            fbResponse.authResponse.accessToken
          ) {
            const data = {
              accessToken: fbResponse.authResponse.accessToken,
            }
            this.socialLogin('/Api/User/LoginWithFacebook', data, owner)
          }
        },
        { scope: 'email' }
      )
    },
    async onAppleLogin() {
      const owner = this

      try {
        const response = await AppleID.auth.signIn()

        if (response.authorization.id_token) {
          const data = {
            appleToken: response.authorization.id_token,
          }
          this.socialLogin('/Api/User/LoginWithApple', data, owner)
        }
      } catch (error) {
        this.error = error
        //app.toast.error(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import 'Login.scss';
</style>
