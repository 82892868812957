<template>
  <DropdownContainer ref="dropdownContainerLoginOverlay">
    <Login
      v-if="isOpen"
      :login-options="loginOptions"
      :login-design="'modal'"
    ></Login>
  </DropdownContainer>
</template>

<script>
import { nextTick } from 'vue'

import { app } from '@/main'

import Login from './Login.vue'
import DropdownContainer from '@shared/DropdownContainer/DropdownContainer.vue'

export default {
  components: {
    Login,
    DropdownContainer,
  },
  data() {
    return {
      callback: undefined,
      isOpen: false,
    }
  },
  props: {
    loginOptions: { default: null, type: Object },
  },
  created() {
    app.mitt.on('login-complete', this.closeForm)
    app.mitt.on('prompt-login', this.openForm)
    app.mitt.on('create-complete', this.closeForm)
  },
  methods: {
    closeForm(favorites) {
      this.$refs.dropdownContainerLoginOverlay.close()

      this.isOpen = false
      const owner = this
      setTimeout(() => {
        if (favorites !== undefined && owner.callback !== undefined) {
          owner.callback()
        }
      }, 100)
    },
    openForm(callback) {
      this.callback = callback
      this.isOpen = true
      nextTick(() => {
        this.$refs.dropdownContainerLoginOverlay.open(callback)
      })
    },
  },
}
</script>
