<template>
  <form class="user" method="post" action="reset-password">
    <div class="user__content" v-if="!passwordChanged">
      <div class="user__input-container user__input-container--password">
        <p class="error my-1" v-if="errorMessagePassword">
          <span>{{ errorMessagePassword }}</span>
          <span class="error__attention"></span>
        </p>
        <div class="input-container">
          <input
            title="Kodeord"
            type="password"
            name="Password"
            id="userLoginPassword"
            placeholder="Kodeord"
            autocomplete="new-password"
            class="user__input form-control"
            v-model="password"
            @keyup.enter="resetPassword()"
          />
          <label class="user__input-label control-label" for="userLoginPassword"
            >Kodeord</label
          >
        </div>
      </div>
      <div class="user__input-container user__input-container--password">
        <p class="error my-1" v-if="errorMessagePasswordRepeat">
          <span>{{ errorMessagePasswordRepeat }}</span>
          <span class="error__attention"></span>
        </p>
        <div class="input-container">
          <input
            title="Gentag kodeord"
            type="password"
            name="PasswordRepeat"
            id="userLoginPasswordRepeat"
            placeholder="Gentag kodeord"
            autocomplete="new-password"
            class="user__input form-control"
            v-model="passwordRepeat"
            @keyup.enter="resetPassword()"
          />
          <label
            class="user__input-label control-label"
            for="userLoginPasswordRepeat"
            >Gentag kodeord</label
          >
        </div>
      </div>
      <div class="user__button-container">
        <div class="user__button-flex">
          <button
            type="submit"
            class="user__button button button--highlight"
            :disabled="inProgress"
            @click.prevent="resetPassword()"
          >
            Gem
            <ButtonSpinner v-if="inProgress" />
          </button>
        </div>
      </div>
    </div>
    <div class="user__content" v-if="passwordChanged">
      <h4>
        Dit kodeord er blevet ændret. Fortsæt til login
        <a class="user__anchor" :href="loginLink">her</a>.
      </h4>
    </div>
  </form>
</template>

<script>
import { app } from '@/main'

import ButtonSpinner from '@components/Shared/ButtonSpinner/ButtonSpinner.vue'

export default {
  components: { ButtonSpinner },
  data() {
    return {
      password: '',
      passwordRepeat: '',
      timer: null,
      timeout: 500,

      errorMessagePassword: '',
      errorMessagePasswordRepeat: '',

      requirementsFulfilled: false,
      inProgress: false,
      passwordChanged: false,
      loginLink: '',
    }
  },
  props: {
    token: { default: '', type: String },
  },
  methods: {
    isNullOrEmpty(str) {
      if (str === null) return true
      if (typeof str === 'undefined') return true
      if (str === '') return true
      if (typeof str === 'string') {
        if (str.trim() === '') return true
      }
      return false
    },
    clearErrorMessages() {
      this.errorMessagePassword = ''
      this.errorMessagePasswordRepeat = ''
    },
    validateRequirements() {
      if (
        !this.isNullOrEmpty(this.password) &&
        !this.isNullOrEmpty(this.passwordRepeat)
      ) {
        this.requirementsFulfilled = true
      } else {
        this.requirementsFulfilled = false
      }
    },
    checkErrors(errorsArray) {
      errorsArray.forEach(item => {
        const errorItem = item.errorItem.toLowerCase()
        const errorMessage = item.errorMessage

        if (errorItem === 'password') {
          this.errorMessagePassword = errorMessage
        }

        this.requirementsFulfilled = false
      })
    },
    formValidation() {
      this.clearErrorMessages()
      this.requirementsFulfilled = true

      if (this.isNullOrEmpty(this.password)) {
        this.errorMessagePassword = 'Du skal angive en ny kode'
        this.requirementsFulfilled = false
      }

      if (this.isNullOrEmpty(this.passwordRepeat)) {
        this.errorMessagePasswordRepeat = 'Du skal angive den nye kode igen'
        this.requirementsFulfilled = false
      }

      if (this.requirementsFulfilled && this.password !== this.passwordRepeat) {
        this.errorMessagePasswordRepeat = 'Kodeord og gentagelse skal være ens'
        this.requirementsFulfilled = false
      }
    },
    resetPassword() {
      this.inProgress = true
      this.formValidation()

      if (!this.requirementsFulfilled) {
        this.inProgress = false
        return
      }

      const data = {
        token: this.token,
        password: this.password,
      }

      app.axios
        .post('/Api/User/ResetPassword', JSON.stringify(data))
        .then(resp => {
          if (
            resp.data.errorCollection &&
            resp.data.errorCollection.length > 0
          ) {
            this.inProgress = false
            this.checkErrors(resp.data.errorCollection)
            return
          }

          this.inProgress = false
          this.passwordChanged = true
          this.loginLink = resp.data.data
          this.password = ''
          this.passwordRepeat = ''
          history.replaceState(null, '', null)
          app.toast.success(
            'Du har nu ændret dit kodeord',
            'Gå til login',
            true,
            () => {
              if (resp.data && !resp.data.data) return
              window.location.href = resp.data.data
            }
          )
        })
    },
  },
  watch: {
    password() {
      this.errorMessagePassword = ''
      this.validateRequirements()
    },
    passwordRepeat() {
      this.errorMessagePasswordRepeat = ''
      this.validateRequirements()
    },
  },
}
</script>

<style lang="scss">
@import '../User.scss';
</style>
